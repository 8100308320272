<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('identity.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent" style="    background: #181e34;">
		<van-row>
			 <div class="form_cell_item">
				  <div class="form_cell_label">{{$t('identity.realname')}}</div>
				  <input :placeholder="$t('identity.notice1')" v-model="identity.realname"/>
			 </div>
			 <div class="form_cell_item">
				  <div class="form_cell_label">{{$t('identity.regions')}}</div>
				  <input :placeholder="$t('identity.notice2')" v-model="identity.country"/>
			 </div>
			 <div class="form_cell_item">
				  <div class="form_cell_label">{{$t('identity.address')}}</div>
				  <input :placeholder="$t('identity.notice3')" v-model="identity.address"/>
			 </div>
		</van-row>
      <van-row class="spacing">
        <van-form ref="form" v-if="false">
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('identity.realname') }}</span>
            </van-row>
            <van-field
              v-model="identity.realname"
              :placeholder="$t('identity.notice1')"
              :rules="[{ required: true, message: '该项是必填项' }]"
            />
          </van-row>
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('identity.regions') }} </span>
            </van-row>
            <van-field
              v-model="identity.country"
              :placeholder="$t('identity.notice2')"
              :rules="[{ required: true, message: '该项是必填项' }]"
            />
          </van-row>
          <van-row class="field">
            <van-row
              style="border-bottom: 1px solid rgb(208, 208, 209); padding-bottom: 10px;"
              ><span>{{ $t('identity.address') }} </span>
            </van-row>
            <van-field
              v-model="identity.address"
              :placeholder="$t('identity.notice3')"
              :rules="[{ required: true, message: '该项是必填项' }]"
            />
          </van-row>
        </van-form>
        <van-button
          v-if="identity.oauth === '0'"
          class="submitBtn"
          @click.prevent="onSubmit"
          >{{ $t('common.submit') }}</van-button
        >
        <van-button v-if="identity.oauth === '1'" class="submitBtn">{{
          $t('identity.auth1')
        }}</van-button>
        <van-button v-if="identity.oauth === '2'" class="submitBtn">{{
          $t('identity.auth2')
        }}</van-button>
      </van-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      identity: {},
      rules: []
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/home/getindentity')
      if (data) {
        if (data.code === 200) {
          this.identity = data.data
        }
      }
    },
    async onSubmit() {
		if(!this.identity.realname){
			return this.$toast(this.$t('identity.notice1'))
		}
		if(!this.identity.country){
			return this.$toast(this.$t('identity.notice2'))
		}
		if(!this.identity.address){
			return this.$toast(this.$t('identity.notice3'))
		}
      const { data } = await this.$http.post(
        '/home/home/identity',
        this.identity
      )
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
          setTimeout(() => {
            this.$router.push('/index')
          }, 300)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

	.form_cell_item{
		padding: 0.6rem;
		display: flex;
		align-items: center;
		position: relative;
		&::after {
		    content: " ";
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    right: 0;
		    height: 0.026667rem;
		    border-bottom: 0.026667rem solid #e5e5e5;
		    color: #e5e5e5;
		    -webkit-transform-origin: 0 100%;
		    -ms-transform-origin: 0 100%;
		    transform-origin: 0 100%;
		    -webkit-transform: scaleY(0.5);
		    -ms-transform: scaleY(0.5);
		    transform: scaleY(0.5);
		    z-index: 2;
		}
		.form_cell_label{
			width: 115px;
			font-size: 18px;
			color: #8686aa;
			text-align: left;
		}
		input{
			width: 100%;
			flex: 1;
			padding: 20px;
			box-sizing: border-box;
			background-color: transparent;
			color: #8686aa;
			outline: none;
			font-size: 18px;
			border: none;
		}
	}
.maincontent {
  padding-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .spacing {
    margin-top: 1rem;
    .field {
      background-color: #f1f1f2;
      margin-top: 0.53333rem;
      width: 90%;
      margin-left: 5%;
      border-radius: 0.53333rem;
      padding: 1.06667rem;
      span {
        color: #000;
        font-size: 1.86667rem;
      }
      .van-cell {
        font-size: 0.98667rem;
        background-color: #f1f1f2 !important;
        color: #fff !important;
        height: 4rem;
      }
      .van-field__control {
        padding-right: 1.2rem;
      }
      /deep/.van-field input {
        color: #000;
        text-align: center;
      }
      /deep/.van-field__error-message {
        text-align: center;
      }
    }
    .submitBtn {
      margin-top: 30px;
      width: 90%;
      letter-spacing: 0.53333rem;
      height: 3rem;
      font-size: 0.93333rem;
      color: #fff;
      background-color: #ff571e;
      border-radius: 0.23333rem;
      border: none;
    }
  }
}
</style>
